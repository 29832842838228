import React, {FC} from 'react'
import {IStepChildrenProps} from '@common/types'
import {
  EBlockPosition,
  EBlockType,
  EComponent,
  IAccordionComponent,
  IBannerComponent,
  IBlockQuoteComponent,
  ICaseListComponent,
  IEmbedComponent,
  IFormBlockComponent,
  IGridComponent,
  IListComponent,
  IMediaComponent,
  IParagraphComponent,
  IQuoteComponent,
  IStackComponent,
  ITagListComponent,
  IVacancyListComponent,
  TStepChildPosition
} from '@redux/types'
import Grid from '@common/components/PageStep/StepGrids'
import List from '@components/PageStep/StepChildren/ExclusiveStepComponents/List'
import Picture from '@common/components/common/Picture'
import RichTextContent from '@common/components/RichTextContent'
import VacancyList from '@common/components/PageStep/StepChildren/ExclusiveStepComponents/VacancyList'
import Quote from '@components/PageStep/StepChildren/ExclusiveStepComponents/Quote'
import Banner from '@common/components/PageStep/StepChildren/ExclusiveStepComponents/Banner'
import Table from '@common/components/PageStep/StepChildren/ExclusiveStepComponents/Table'
import Accordion from '@common/components/PageStep/StepChildren/ExclusiveStepComponents/Accordion'
import EmbedMedia from '@common/components/common/EmbedMedia'
import Tags from '@components/Tags'
import FormBlock from '@common/components/PageStep/StepChildren/ExclusiveStepComponents/FormBlock'
import ProjectGrid from '@common/components/ProjectGrid'
import BlogQuote from './ExclusiveStepComponents/BlogQuote'

/**
 * Компонент-хаб для распределения информации о том, каким образом должен быть отображен контент шага.
 * Распределение реализовано через switch по типу компонента контента. В шаге их может быть несколько
 * @param components - контент шага - массив элементов типа TComponent
 * @param parentStep - шаг, чей контент отрисовывается.
 * @constructor
 */
const StepChildren: FC<IStepChildrenProps> = ({components, parentStep}) => {
  return (
    <>
      {components.map((c, i) => {
        const key = `step_item_${i}_${c.__component}`
        switch (c.__component) {
          case EComponent.GRID:
            const grid = c as IGridComponent
            return (
              <Grid
                key={key}
                items={grid.cards}
                parent={parentStep || null}
                height={grid?.height || null}
                type={grid?.placement?.type || EBlockType.grid}
                position={grid?.placement?.position || EBlockPosition.insideStep}
              />
            )

          case EComponent.STACK_LIST:
            const stacks = c as IStackComponent

            return <Grid key={key} items={stacks.stacks} componentType={c.__component} />

          case EComponent.TAG_LIST:
            const tagList = c as ITagListComponent
            const marginTagClassesByType = {
              [EBlockType.sticky]: 'mt-20 mt-md-20 mt-sm-10',
              [EBlockType.simple]: 'inner-styled-list--padding-big mt-65 mt-md-65 mt-sm-40',
              [EBlockType.grid]: 'inner-styled-list--padding-middle mt-40 mt-md-40 mt-sm-20',
              [EBlockType.table]: 'mt-20 mt-md-20 mt-sm-10',
              [EBlockType.tools]: 'mt-20 mt-md-20 mt-sm-10'
            }[tagList?.placement?.type || EBlockType.grid]

            return <Tags key={key} tags={tagList?.tags?.map(t => t.title) || []} classNames={marginTagClassesByType} />

          case EComponent.CASE_LIST:
            const caseList = c as ICaseListComponent
            const cases = caseList.cases
            return (
              <div className="project">
                <ProjectGrid key={key} projects={cases || []} isHomePageGrid={false} />
              </div>
            )

          case EComponent.LIST:
            const list = c as IListComponent
            if (parentStep?.type === 'inner-table') {
              return (
                <Table
                  key={key}
                  position={(list.placement?.position as TStepChildPosition) || EBlockPosition.insideStep}
                  items={
                    list.lists?.map(e => ({
                      title: e?.title || '',
                      description: e?.description,
                      text: e?.text
                    })) || []
                  }
                />
              )
            }
            return (
              <List
                key={key}
                items={list.lists}
                height={list?.height || null}
                type={list.placement?.type || null}
                position={(list.placement?.position as TStepChildPosition) || EBlockPosition.insideStep}
              />
            )

          case EComponent.MEDIA:
            const media = c as IMediaComponent
            const marginMediaClassesByType = {
              [EBlockType.sticky]: 'mt-40 mt-md-20 mt-sm-15',
              [EBlockType.simple]: 'mt-150 mt-md-65 mt-sm-40',
              [EBlockType.grid]: 'mt-65 mt-md-65 mt-sm-40',
              [EBlockType.table]: 'mt-65 mt-md-40 mt-sm-40',
              [EBlockType.tools]: 'mt-65 mt-md-40 mt-sm-40'
            }[media?.placement?.type || EBlockType.grid]
            const isOutsideStep = c?.placement?.position === EBlockPosition.outsideStep && c?.placement?.type !== EBlockType.sticky

            const positionClassName = isOutsideStep ? 'mt-0 mt-md-0 mt-sm-0' : marginMediaClassesByType
            const marginMediaClassesByPosition = c?.placement?.position !== EBlockPosition.insideStepDescription ? '' : ' mb-sm-20'

            return <Picture key={key} media={media} wrapperClassName={`${positionClassName}${marginMediaClassesByPosition}`} />

          case EComponent.EMBED:
            const embed = c as IEmbedComponent
            return (
              <EmbedMedia
                key={key}
                embed={embed?.embed}
                position={embed?.placement?.position || EBlockPosition.insideStep}
                type={embed?.placement?.type || EBlockType.grid}
              />
            )

          case EComponent.VACANCY_LIST:
            const vacancyList = c as IVacancyListComponent
            return (
              <VacancyList
                key={key}
                items={
                  vacancyList.vacancies?.data
                    .map(v => v.attributes)
                    .map(v => ({
                      slug: `/vacancies/${v.slug}`,
                      title: v.title,
                      meta: {
                        onlyPreview: v.meta?.onlyPreview ?? false
                      }
                    })) || []
                }
              />
            )

          case EComponent.PARAGRAPH:
            const paragraph = c as IParagraphComponent
            return (
              <RichTextContent
                key={key}
                title={paragraph?.title || ''}
                type={c?.placement?.type || EBlockType.grid}
                position={c?.placement?.position || EBlockPosition.insideStep}
                parent={parentStep || null}
                content={paragraph?.text}
                paragraphType={paragraph?.type}
              />
            )

          case EComponent.QUOTE:
            const quote = c as IQuoteComponent
            return <Quote quote={quote} key={key} />

          case EComponent.BLOG_QUOTE:
            const blogQuote = c as IBlockQuoteComponent
            return <BlogQuote quote={blogQuote} key={key} />

          case EComponent.BLOCK:
            const banner = c as IBannerComponent
            return <Banner banner={banner} key={key} />

          case EComponent.ACCORDION:
            const accordion = c as IAccordionComponent
            return <Accordion items={accordion?.spoilers || []} key={key} />

          case EComponent.FORM_BLOCK:
            const formBlock = c as IFormBlockComponent
            return (
              <FormBlock
                color={formBlock.color || ''}
                key={key}
                title={formBlock?.title || ''}
                position={formBlock?.placement?.position || EBlockPosition.insideStep}
              />
            )
        }
      })}
    </>
  )
}

export default StepChildren
