import React, {FC, memo} from 'react'
import {IBlogQuoteProps, IQuoteProps} from '@common/types'
import RichTextContent from '@components/RichTextContent'
import {MaskedImage} from './MaskedImage'
import Icon from '@common/components/common/Icon'
import useWindowWidth from '@common/hooks/useWindowWidth'

const BlogQuote: FC<IBlogQuoteProps> = ({quote}) => {
  let cardClasses = ['inner-blog-quote__card', `inner-blog-quote__color--${quote.color ?? 'black'}`].join(' ')
  const image = quote?.person?.image?.data?.attributes || null
  const mobileImg = quote.person.imageMobile?.data?.attributes || null

  const width = useWindowWidth()

  const imgSize = width > 768 ? '360' : '300'

  return (
    <div className={cardClasses}>
      <div className="inner-blog-quote__header">
        <div className="inner-blog-quote__logo">
          <Icon width={17} height={24} name="icon-logo-spiral" />
          <Icon width={72} height={24} name="icon-logo-letters" />
        </div>
      </div>
      <div className="inner-blog-quote__content">
        <div className="inner-blog-quote__img-wrapper">
          <MaskedImage img={image} mobileImg={mobileImg} width={imgSize} height={imgSize} />
        </div>
        <div className="inner-blog-quote__text-content">
          {quote?.text && (
            <div className="inner-blog-quote__text">
              <RichTextContent content={quote?.text} />
            </div>
          )}
          <div className="inner-blog-quote__author">
            <div className="inner-blog-quote__author-info">
              <p>{quote.person?.position}</p>
              <p>{quote.person?.fullName}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(BlogQuote)
